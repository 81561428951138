//
//
//
//
//
//
//
//

export default{
	data(){
		return{
			isMusic:true,
		}
	},
	mounted() {
		this.audioAutoPlay()
	},
	methods:{
		audioAutoPlay() {
			let audioPlay = document.getElementById('bg-music');
			audioPlay.play();
			// ios音乐播放
			document.addEventListener(
				"WeixinJSBridgeReady", () => {
					audioPlay.play()
			},false);
		},
		missusFun(){
			this.isMusic = !this.isMusic;
			if (this.isMusic) {
				this.audioAutoPlay();
			} else {
				let media = document.querySelector("#bg-music");
				media.pause();
			}
		}
	}
}
